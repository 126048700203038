import { Link } from "react-router-dom";
import phone from "../Assets/phone (1).svg";
import mail from "../Assets/Email (1).svg";
import location from "../Assets/location (1).svg";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";

const Footer = () => {
  return (
    <>
      <section className="footer mt-24 font-Poppins bg-[#4b4a67] text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="">
                <div className="">
                  <Link to="/" className="no-underline">
                   <h1 className="text-xl text-white font-bold mt-8">Avatar Technology</h1>
                  </Link>
                </div>
                <p className="pt-3 font-medium m-0">
                  At Avatar Technology you can digitally transform your business
                  with our top-notch solutions. We offer Software development
                  solutions to reinvent.
                </p>
              </div>

              <h1 className="font-semibold text-2xl pt-4">Follow Us On</h1>

              <div className="flex px-1 pb-4 ">
                <a href="" target="_blank" rel="noreferrer">
                  <FaFacebookF
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg     "
                  />
                </a>
                <a href="" target="_blank" rel="noreferrer">
                  <AiOutlineInstagram
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg     "
                  />
                </a>
                <a href="" target="_blank" rel="noreferrer">
                  <GrLinkedinOption
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg     "
                  />
                </a>
                <a href="" target="_blank" rel="noreferrer">
                  <FaTwitter
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg   "
                  />
                </a>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pt-4 lg:pt-16">
              <div className="">
                <h3>Our services</h3>
                <ul className="p-0 ">
                  <li className="pb-3">
                    <Link
                      to="/"
                      className="text-white font-medium no-underline "
                    >
                      Web Development
                    </Link>
                  </li>

                  <li className="pb-3">
                    <Link
                      to="/"
                      className="text-white font-medium no-underline"
                    >
                      UI/UX Design
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link
                      to="/"
                      className="text-white font-medium no-underline"
                    >
                      SEO
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link
                      to="/"
                      className="text-white font-medium no-underline"
                    >
                      AI/ML
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pt-4 lg:pt-16">
              <div className="">
                <h3>Contact Us</h3>
                <ul className="p-0">
                  <a
                    className="text-white no-underline"
                    href="tel:(+92)308-8888034"
                  >
                    {" "}
                    <li className="font-medium flex pb-3 gap-2 pt-1 items-center">
                      <img src={phone} className="w-4 h-4" alt="phone" />
                      (+971) 561666506
                    </li>
                  </a>
                  <a
                    className="text-white no-underline"
                    href="mailto:payments@avatartechnology.org "
                  >
                    {" "}
                    <li className="font-medium flex pb-3 gap-2 pt-1 items-center">
                      <img src={mail} className="w-4 h-4" alt="mail" />
                      payments@avatartechnology.org{" "}
                    </li>
                  </a>
                  <a className="text-white no-underline" href="">
                    {" "}
                    <li className="font-medium flex pb-3 gap-2 pt-1 items-baseline">
                      <img src={location} className="w-4 h-4" alt="location" />
                      Sharjah Al Nasserya Street-Zohra City/Town Sharjah
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#4b4a67] text-white border-t-[1px] border-white font-Poppins">
        <div className="container py-2">
          <div className="row flex justify-between">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 flex justify-center md:justify-start lg:justify-start">
              <div className="p-0">
                <p className="m-0 text-xs md:text-sm lg:text-base ">
                  copyright@AvatarTechnology. All rights are reserved
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 flex justify-center md:justify-end lg:justify-end">
              <div>
                <ul className="flex justify-end gap-10 m-0 p-0 ">
                  <Link className="text-white no-underline" to="/">
                    <li className="text-xs md:text-sm lg:text-base ">
                      Privacy Policy{" "}
                    </li>
                  </Link>

                  <Link className="text-white no-underline" to="/">
                    <li className="text-xs md:text-sm lg:text-base  ">
                      Terms And Condition{" "}
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
