import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Loader from "./loader/Loader";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

// Lazy-loaded components
const Home = lazy(() => import("./pages/Home"));


function App() {
  return (
    <>
      <BrowserRouter>
      <div style={{ position: "relative", zIndex: "10000" }}>
          <div className="bg-[#4b4a67] header-top border-b-[1px] border-white  py-1 font-Poppins ">
            <div className=" container flex justify-between ">
              <div className="flex px-1 ">
                <a
                  className="flex items-center"
       href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF
                    size={25}
                    className="p-[4px] mr-2  text-[#4b4a67]  bg-white rounded-lg    "
                  />
                </a>
                <a
                  className="flex items-center"
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlineInstagram
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg    "
                  />
                </a>
                <a
                  className="flex items-center"
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <GrLinkedinOption
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg    "
                  />
                </a>
                <a
                  className="flex items-center"
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter
                    size={25}
                    className="p-[4px] mr-2 text-[#4b4a67]  bg-white rounded-lg     "
                  />
                </a>
              </div>
              <div className="flex items-center">
                <a
                  href="mailto:payments@avatartechnology.org"
                  className=" no-underline text-right text-white justify-center text-[16px] flex items-end lg:justify-end "
                >
                  <MdEmail
                    size={30}
                    className="rounded pr-2 pt-2 text-white "
                    onClick={() => alert("hello")}
                  />
                payments@avatartechnology.org
                </a>
              </div>
            </div>
          </div>
        </div>
        <Navbar className="font-Poppins " />

        <div className="font-Poppins overflow-hidden text-black">
          <Suspense fallback={<div><Loader/></div>}>
            <Routes>
              <Route path={`/`} element={<Home />} />
            
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
