import {React, useState} from "react";
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai";
import {Link} from "react-router-dom";

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const handleClose = () => setNav(!nav);


    const handleNav = () => {
        setNav(!nav);
    };




    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-[#4b4a67] sticky-top font-Poppins navbar--container ">
            <div className=" container ">

                <div className="bg-transparent w-100 " style={{left: 0, top: 0}}>
                    <div className="w-100 h-[100px] bg-[#4b4a67]">
                        <div className=" flex justify-between items-center h-full  container px-0" id="scrollDiv">
                            <Link to="/" className="no-underline">
                               <h1 className="text-xl text-white font-bold">Avatar Technology </h1>
                            </Link>
                            <div className="hidden md:flex">
                                <ul className="flex text-white  text-base items-center gap-4 relative">
                                
                                    
                                    <a
                                         href="mailto:payments@avatartechnology.org "
                                        className=" bg-white1 border-white border-[1px] bg-white px-2 no-underline text-[#4b4a67] py-2 rounded-lg text-base md:text-sm lg:text-base contact-btn  "
                                    >
                                        Contact Us
                                    </a>
                                </ul>
                            </div>

                            {/* Hamburger menu */}

                            <div onClick={handleNav} className=" md:hidden">

                                {nav ? (
                                    <AiOutlineClose
                                        size={30}
                                        className="text-white z[1] mr-2 delay-500"
                                    />
                                ) : (
                                    <AiOutlineMenu size={30} className="text-white mr-2"/>
                                )}
                            </div>

                            {/* Mobile menu */}
                            <div
                                className={
                                    nav
                                        ? "w-full  bg-black text-white absolute h-[20vh] z-[100] top-[115px]  py-4 items-start right-[0%] flex justify-center text-center"
                                        : "absolute left-[-100%] md:hidden lg:hidden"}>
                                <ul className="md:hidden lg:hidden pl-0 w-100">
                                   
                                    

                                    <a            href="mailto:payments@avatartechnology.org " onClick={handleClose}
                                          className="  no-underline  my-3 w-100 flex justify-center ">
                                        <li className=" list-none bg-white bg-white1 w-[200px]  px-3 rounded-lg text-[#4b4a67] font-medium py-2 border-[1px] border-white">
                                            Contact Us
                                        </li>
                                    </a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
